<template>
    <main>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="cover1"  style="">

                  </div>
                </div>
                <div class="col-lg-12">
                    <div class="container box-title">
                        <div class="row">
                            <div class="col-lg-12">
                                <h1>Poked Studio Exclusive Cake Club NFT Presale</h1>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-8">
                                <div class="presale-box">
                                    <p>
                                        Launching exclusive presale for poked studio community members. This is the last chance to collect Dick NFT at a discounted price.
                                    </p>
                                    <p>
                                        The Cake Club is a collection of 7777 Dick NFTs unique digital collectible living on the Internet computer.
                                         Each Dick NFT is programically generated from over 150 characteristics.
                                    </p>
                                    <p>
                                       Your Dick NFT doubles as your meme club membership card, future airdrops and grants access to members-only benefits.
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-4 buy-now">
                                <a href="#packages" class="btn">Buy Now</a>
                                 <p class="rw-pgm">
                              Buy 4 or more Dick NFT to enter into the Meme Cake NFT staking program.
                                </p>
                                <p>
                                    Anyone who owns Dick NFT will be eligible for our upcoming Balls Airdrop at 1:1 ratio.
                                </p>
                               
                            </div>
                        </div>
                        <div id="packages" class="row mt-5 package-container justify-content-center">
                            
                            <div class="col-lg-12 mb-3">
                                <h1 style="color:#ff0019">PRE-SALE ENDED </h1>
                                <br>
                                
                                 <p style="text-align: center;font-size:34px;font-weight:bold">
                                  You can participate in our public mint.<br>
                                   To know more please join our <a href="https://discord.gg/WFkRssvNtW" target="_blank" style="color: var(--primary-color);">Discord server.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="user-info-master" v-if="savebox">
            <div class="container ">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="userinfo">
                        <div class="pm-box">
                           <div class="pm-box-header">
                               <h3>Please fill the following</h3>
                           </div>
                           <div class="pm-box-body">
                               <div class="row justify-content-center">
                                   <div class="col-lg-10 mt-5 mb-5">
                                       
                                           <div class="mb-3">
                                               <label for="useremail" class="form-label">Enter your email</label>
                                                <input type="email" class="form-control" id="useremail" placeholder="" name="useremail" v-model="userInfo.email">
                                            </div>
                                            <div class="mb-3">
                                               <label for="userdiscord" class="form-label">Enter your discord username</label>
                                                <input type="text" class="form-control" id="userdiscord" placeholder="" name="useremail" v-model="userInfo.discord">
                                            </div>
                                            
                                            <div class="mb-3">
                                                <button class="btn" type="submit"  @click.prevent="saveUserData">Save and proceed</button>
                                            </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        
    </main>    
</template>
<style scoped>
.cover1 {
    width: 100%;
    height: 38vh;
    background: url('https://d15bmhsw4m27if.cloudfront.net/public/psb2.jpg');
    background-size: cover;
    background-position: center center;
}
.buy-now{
    position: relative;
    text-align: center;
    padding-top:34px;
}

.box-title{
    margin-top: 40px;
}
.box-title h1{
    text-align: center;
    color: var(--primary-color);
    font-size: 38px;
}
.presale-box {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 5px 25px 0 rgb(123 123 123 / 15%);
    margin-bottom: 20px;
    padding: 15px !important;
    font-size: 18px;
    line-height: 1.5;
    
}
.buy-now .btn{
    width: 80%;
    background:  var(--primary-color);
    color: var(--dark-color);
}
.buy-now p{
    font-size: 16px;
    line-height: 1.5;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
.package{
    width: 100%;
    display: inline-block;
    padding: 30px;
    border: solid 1px rgba(0, 0, 0, .2);
    box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 30%);
    -moz-box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: var(--white-clr) ;
    text-align: center;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    position: relative;
    margin-bottom: 30px;
}

.package[data-package-id="4"]{
    background: var(--primary-color);
    transform: scale(1.05);
}
.package h2{
    color:  var(--primary-color);
    font-size: 32px;
}
.package[data-package-id="4"] h2{
    color: var(--dark-color);
} 
.package p{
    color: var(--dark-color);
    font-weight: bold;
}
.package .icon{
    width: 100px;
    height: 100px;
    display: block;
    margin: 10px auto;
    border-radius: 50%;
    background-position: center !important;
}
.package[data-package-id="4"] .icon{
    border: solid 1px var(--secondary-color);
    box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 30%);   
} 
.package:hover{
    border: solid 1px var(--secondary-color);
    box-shadow: 1px 1px 8px 1px rgb(249 185 60 / 30%);
    -webkit-box-shadow: 1px 1px 8px 1px rgb(249 185 60 / 30%);
    -moz-box-shadow: 1px 1px 8px 1px rgb(249 185 60 / 30%);
}
.rw-pgm {
    font-weight: bolder;
    background-color: #000;
    box-shadow: 0 5px 25px 0 rgb(123 123 123 / 15%);
    margin-bottom: 20px;
    padding: 14px !important;
    color: #f9b93c;
    border-radius: 4px;
}
.user-info-master{
    width: 100%;
    position: fixed;
    background: rgba(0,0,0,.85);
    height: 100%;
    z-index: 101;
    top:0%;
    left: 0%;
}
.user-info-master .container ,.user-info-master .container .row{
    height: 100%;
}
.user-info-master .userinfo{
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align : center;
    -webkit-align-items : center;
    -moz-box-align : center;
    -ms-flex-align : center;
    align-items : center;
}
.user-info-master .userinfo .pm-box{
    width: 100%;
    min-height: 30vh;
    background: var(--white-clr);
    border-radius: 12px;
}
.pm-box .pm-box-header {
    width: 100%;
    padding: 10px 0px;
    border-bottom: 2px solid rgb(0 0 0 / 20%);
}
.pm-box .pm-box-header h3{
    margin: 0%;
    text-align: center;
}
.pm-box .btn{
    width: 100%;
    text-align: center;
    background: var(--primary-color);
    text-decoration: none;
    color: var(--white-clr);
}
.tag{
        position: absolute;
    left: -6%;
    width: 40%;
    top: 0%;
}
@media (max-width: 480px) {
    .buy-now .btn,.buy-now p{
        width: 100%;
    }
    
}
</style>
<script>
import noty from 'noty'
import axios from '@/plugins/axios'

export default {
    name: "Launchpad",
     mounted() {
       
     },
    data () {
        return {
            packages:[
                {"nft":"1 NFT" ,"price": 1.6 , "icp":16_000_000_0,in:1}, // 16_000_000_0
                {"nft":"2 NFT" ,"price": 3.2 , "icp":32_000_000_0 , in:2},
                {"nft":"3 NFT" ,"price": 4.8 , "icp":48_000_000_0 ,in:3},
                {"nft":"4 NFT" ,"price": 6.4 , "icp":64_000_000_0,in:4},
                {"nft":"5 NFT" ,"price": 8 , "icp":80_000_000_0,in:5 , tag:"Best Seller"},
                {"nft":"7 NFT" ,"price": 11 , "icp":110_000_000_0,in:6},
                {"nft":"10 NFT" ,"price": 15 , "icp":160_000_000_0,in:7},
                {"nft":"20 NFT" ,"price": 30 , "icp":320_000_000_0,in:8},
            ],
            baseURL:"https://d15bmhsw4m27if.cloudfront.net/public/packs/",
            trxInprogress:false,
            walletAddress:"7431e612d6ed077398514d1a8cbb7b35d40e638bd61c2e03868be33b729e242e",
            plugWallet:{
                connected:false,
                principalId:'',
           },
           userInfo:{
               email:'',
               discord:''
           },
           savebox:false,
           selectedPackage:false
        }
    },
    methods: {
        InitiateTrx:async function (pid) {
            return false
           // if(this.trxInprogress) return false;
            this.selectedPackage = pid;
            this.trxInprogress=true;
            if (!window.ic) new noty({text: 'You need to integrate your Plug Wallet to make a transaction' , type:"error", layout:"bottomRight" , timeout:1000}).show();
            var result = await window.ic.plug.isConnected();
            
             if(result && this.plugWallet.principalId !='' ){
                  const params = { 
                      to: this.walletAddress,
                      amount: this.packages[pid].icp,
                      memo: 'dickpack '+this.packages[pid].nft,
                };
                const result = await window.ic.plug.requestTransfer(params).catch(error=>{
                    new noty({text: 'Purchase Failed ' , type:"error", layout:"bottomRight" , timeout:1000}).show();
                });
               if(result){
                    axios.post('/api/savesale', { 
                        useremail:this.userInfo.email, userdiscord: this.userInfo.discord, princId:this.plugWallet.principalId,
                        package: this.packages[pid].nft ,trxId:result.height ,campaign:'poked'}).then(res=>{ })
                    this.trxInprogress=false;
                    new noty({text: 'NFT Purchase Successfull. We Will send you confirmation mail soon.' , type:"success", layout:"bottomRight" , timeout:3000}).show();
                }

              }else{
                  this.connectPlug()
              }
        },
        connectPlug: async function(){
            if(window.ic && this.plugWallet.connected == false && !this.plugWallet.principalId){
                var nnsCanisterId = this.$store.state.canisterId
                var whitelist = [nnsCanisterId];
                var host =  this.$store.state.appUrl;
                var result = await window.ic.plug.requestConnect({whitelist , host});
                var principalObj = await window.ic.plug.agent.getPrincipal();
                if(principalObj){
                    this.checkPlugConnected()
                    this.plugWallet.principalId= principalObj.toString();
                    this.fetchUserData()
                }

            }else{
                if(this.plugWallet.connected){
                    new noty({text: 'Plug wallet already connected' , type:"success", layout:"bottomRight" , timeout:1000}).show();
                }else{
                     new noty({text: 'Plug wallet not installed' , type:"error", layout:"bottomRight" , timeout:1000}).show();
                }
                
            }
        },
        checkPlugConnected:async function(){
            var result = await window.ic.plug.isConnected();
            if(result){
                this.plugWallet.connected=true;
            }    
        },
        fetchUserData:function(){
            if(!this.userInfo.email){
                this.savebox = true
            }
        },
        saveUserData:function(){
            if(this.userInfo.email && this.userInfo.discord){
                this.savebox = false;
                this.InitiateTrx(this.selectedPackage)
            }
        }
    }
    
}
</script>
